<template>
  <v-dialog
    v-model="dialog"
    max-width="800"
    scrollable
  >
    <v-card>
      <v-card-title>
        Buscar descuentos
        <v-spacer></v-spacer>
        <v-btn title="Cerrar" icon @click="dialog = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-data-table
          class="cebra elevation-2"
          :headers="headers"
          sort-by="descuento_id"
          :items="datos"
          :loading="load"
          :search="search"
          :footer-props="{'items-per-page-options':[5, 10, 15, 50, 100]}"
          dense
        >
          <template v-slot:top>
            <v-row class="d-flex justify-end pa-3" no-gutters>
              <v-col cols="6" sm="3" md="2">
                <SearchDataTableVue
                  v-model="search"
                />
              </v-col>
            </v-row>
          </template>
          <template v-slot:no-data>
            <v-alert
              class="mx-auto mt-4"
              max-width="400"
              type="warning"
              border="left"
              dense
              text
            >
              No hay datos para los filtros seleccionados
            </v-alert>
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-icon 
              color="success" title="Agregar descuento"
              class="pl-2"
              @click="$emit('traerDatos',item)"
            >
              fas fa-check
            </v-icon>
          </template>
        </v-data-table> 
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SearchDataTableVue from '../util/SearchDataTable.vue'

export default {
  name: 'ModalElegirDescuentoPos',
  data(){
    return{
      load: false,
      search:'',
      //DataTable Descuentos
      headers: [
        { text: 'Código', value: 'descuento_id' },
        { text: 'Tipo Descuento', value: 'tipo_descuento_nombre' },
        { text: 'Nombre descuento', value: 'descuento_nombre' },
        { text: 'Porcentaje', value: 'porcentaje' /*, formatter: format_money*/ },
        { text: 'Accion', value: 'accion', sorteable: false, filtreable: false }
      ]
    }
  },
  props:{
    value: Boolean,
    datos: Array
  },
  computed:{
    dialog:{
      get(){
        return this.value
      },
      set(value){
        this.$emit('input', value)
      }
    }
  },
  components:{
    SearchDataTableVue
  }
}
</script>

<style>

</style>